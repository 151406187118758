import { createFeatureSelector, createSelector } from '@ngrx/store';

import { FactorsState } from './mfa.reducer';

const selectMFAState = createFeatureSelector<FactorsState>('mfa');

export const selectEnrolledFactors = createSelector(
  selectMFAState,
  (state) => state.enrolledFactors
);

export const selectMFACatalog = createSelector(
  selectMFAState,
  (state) => state.factorCatalog
);

export const selectMFALoading = createSelector(
  selectMFAState,
  (state) => state.loading
);

export const selectMFAError = createSelector(
  selectMFAState,
  (state) => state.error
);

export const selectEnrollingSmsFactor = createSelector(
  selectMFAState,
  (state) => state.enrollingSmsFactor
);

export const selectEnrollmentError = createSelector(
  selectMFAState,
  (state) => state.enrollmentError
);

export const selectActivatingFactor = createSelector(
  selectMFAState,
  (state) => state.activatingFactor
);

export const selectActivationError = createSelector(
  selectMFAState,
  (state) => state.activationError
);

export const selectDeletingFactor = createSelector(
  selectMFAState,
  (state) => state.deletingFactor
);

export const selectDeletionError = createSelector(
  selectMFAState,
  (state) => state.deletionError
);

export const MFAEnrollResponse = createSelector(
  selectMFAState,
  (state) => state.enrollFactorResponse
);

export const selectUserError = createSelector(
  selectMFAState,
  (state) => state.error
);

export const mfaTechDifficultiesStatus = createSelector(
  selectMFAState,
  (state) => state.mfaTechDifficulties
);

export const getMFASelectionStatus = createSelector(
  selectMFAState,
  (state) => state.mfaSelectionStatus
);

export const selectGuestEnrolledFactors = createSelector(
  selectMFAState,
  (state) => state.guestEnrolledFactors
);

export const getVerifiedFactorStatus = createSelector(
  selectMFAState,
  (state) => state.verifiedFactor
);

export const getVerifiedFactorError = createSelector(
  selectMFAState,
  (state) => state.verifyError
);

export const getVerifyFactorErrorCount = createSelector(
  selectMFAState,
  (state) => state.verifyFactorErrorCount
);
