import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { MFAStatusResponse } from '@patient-ui/shared/models';

import { MFAActions } from './mfa.actions';
import { MFAService } from './mfa.service';
@Injectable()
export class MFAEffects {
  constructor(private actions$: Actions, private mfaService: MFAService) {}

  loadEnrolledFactors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MFAActions.loadEnrolledFactors),
      switchMap(() =>
        this.mfaService.getEnrolledFactors().pipe(
          map((factors) => MFAActions.loadEnrolledFactorsSuccess({ factors })),
          catchError((error) =>
            of(MFAActions.loadEnrolledFactorsFailure({ error }))
          ),
          take(1)
        )
      )
    )
  );

  loadMFACatalog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MFAActions.loadMFACatalog),
      switchMap(() =>
        this.mfaService.getMFACatalog().pipe(
          map((factorCatalog) =>
            MFAActions.loadMFACatalogSuccess({ factorCatalog })
          ),
          catchError((error) =>
            of(MFAActions.loadMFACatalogFailure({ error }))
          ),
          take(1)
        )
      )
    )
  );

  enrollFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MFAActions.enrollFactor),
      switchMap(({ factorType, phoneNumber }) =>
        this.mfaService.enrollFactor(factorType, phoneNumber).pipe(
          map((enrollFactorResponse) =>
            MFAActions.enrollFactorSuccess({ enrollFactorResponse })
          ),
          catchError((error) => of(MFAActions.enrollFactorFailure({ error })))
        )
      )
    )
  );

  activateFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MFAActions.activateFactor),
      switchMap(({ factorId, passCode }) =>
        this.mfaService.activateFactor(factorId, passCode).pipe(
          map((factor) => MFAActions.activateFactorSuccess({ factor })),
          catchError((error) => of(MFAActions.activateFactorFailure({ error })))
        )
      )
    )
  );

  deleteFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MFAActions.deleteFactor),
      switchMap(({ factorId, enrolledDataLength }) =>
        this.mfaService.deleteFactor(factorId).pipe(
          map(() => {
            const data: Action[] = [MFAActions.deleteFactorSuccess()];
            if (enrolledDataLength === 1) {
              data.push(MFAActions.clearEnrolledFactors());
            }
            return data;
          }),
          switchMap((data) => from(data)),
          catchError((error) => of(MFAActions.deleteFactorFailure({ error })))
        )
      )
    )
  );

  retriveMFAStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MFAActions.retriveMFAStatus),
      mergeMap(() =>
        this.mfaService.retriveMFAStatus().pipe(
          map((response: MFAStatusResponse) => {
            const mfaSelectionStatus = response ? response.enrolled : null;
            return MFAActions.retriveMFAStatusSuccess({ mfaSelectionStatus });
          }),
          catchError((error) =>
            of(MFAActions.retriveMFAStatusFailure({ error }))
          )
        )
      )
    )
  );

  setMFAPreference$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MFAActions.setMFAPreference),
      switchMap(({ preference }) =>
        this.mfaService.setMFAPreference(preference).pipe(
          map(() => MFAActions.retriveMFAStatus()),
          catchError((error) =>
            of(MFAActions.setMFAPreferenceFailure({ error }))
          )
        )
      )
    )
  );

  //Guest enrollment Verification Flow
  loadGuestEnrolledFactors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MFAActions.loadGuestEnrolledFactors),
      switchMap(({ oktaID }) =>
        this.mfaService.getGuestEnrolledFactors(oktaID).pipe(
          map((factors) =>
            MFAActions.loadGuestEnrolledFactorsSuccess({ factors })
          ),
          catchError((error) =>
            of(MFAActions.loadGuestEnrolledFactorsFailure({ error }))
          ),
          take(1)
        )
      )
    )
  );

  verifyFactor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MFAActions.verifyFactor),
      switchMap(({ factorId, oktaID, passCode }) =>
        this.mfaService.verifyFactor(factorId, oktaID, passCode).pipe(
          map((factor) => MFAActions.verifyFactorSuccess({ factor })),
          catchError((error) => of(MFAActions.verifyFactorFailure({ error })))
        )
      )
    )
  );
}
